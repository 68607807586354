var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"crud-content",attrs:{"fill-height":""}},[_c('ListLayout',{attrs:{"title":_vm.title,"info":_vm.info},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('FilterContainer',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('invoice-form-filter',{on:{"onSubmit":_vm.onSubmitFilterHandler,"onReset":_vm.onResetFilterHandler}})]},proxy:true}])})]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"headers":_vm.header,"loading":_vm.loading,"items":_vm.content,"sortable":_vm.sortable,"options":_vm.paginationOptions,"server-items-length":_vm.totalElements,"multi-sort":"","footer-props":{
          'items-length': 100,
          'items-per-page-text': 'Linhas por página',
          'items-per-page-options': [10, 20, 50],
          pageText: '{0}-{1} de {2}',
        }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.provisionItemsIndicator",fn:function(ref){
        var item = ref.item;
return [(item.provisionItemsIndicator)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-outline")]):_vm._e()]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"camelcase",class:_vm.getColorStatus(item.status)},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDetail(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify-plus-outline ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.admin && (item.status === _vm.statusEnum.AGUARDANDO_FECHAMENTO))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.invoiceERPNotification(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-replace-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Transmitir ao ADM ")])]),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('img',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"src":require("@/assets/images/icons/MDI - file-download-outline.svg"),"color":"primary"}},'img',attrs,false),on))]}}],null,true)},[_c('v-list',_vm._l((_vm.types),function(type,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.onDropwdownClickHandler(type, item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(type.title))])],1)}),1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.admin && (item.status === _vm.statusEnum.FECHADA || item.status === _vm.statusEnum.PAGO))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.openAttachment(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-paperclip ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Anexos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.admin && item.status === _vm.statusEnum.FECHADA)?_c('div',_vm._g(_vm._b({staticClass:"icon-pagamento"},'div',attrs,false),on),[_c('v-img',{attrs:{"src":require("@/assets/images/pagamento.svg")},on:{"click":function($event){return _vm.openPayment(item.id, item.sellerId)}}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Pagamento")])])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pt-10 pb-10"},[_vm._v(" Nenhum resultado encontrado ")])]},proxy:true}],null,true)}),_c('AttachmentModal',{attrs:{"showModal":_vm.showAttachmentModal,"idInvoice":_vm.idInvoice,"close":_vm.closeAttachment}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }